import { TwoElementResizablePage } from 'layouts';

interface Props {
  leftNode: React.ReactNode;
  rightNode: React.ReactNode;
}
export function HorizontalDashboard(props: Props) {
  const { leftNode, rightNode } = props;
  return (
    <TwoElementResizablePage
      minLeftWidth={50}
      maxLeftWidth={80}
      minRightWidth={30}
      maxRightWidth={80}
      defaultLeftWidth={65}
      leftChildren={leftNode}
      rightChildren={rightNode}
    />
  );
}
