import { DateTime } from 'luxon';
import { DisplayableRoutePoint } from '../CreateShipmentForm/components/RoutesForm/types';
import { CreateShipmentFormState } from '../CreateShipmentForm/types';

// route points passed to create shipment form when template has no route points
const DEFAULT_ROUTE_POINTS = [{
  stop_type: 'ORIGIN' as RoutePointStopType,
  stop_operation: 'PICKUP' as const,
  point_number: 1,
  start_time: DateTime.now().toFormat("yyyy-MM-dd'T'T"),
  end_time: DateTime.now().toFormat("yyyy-MM-dd'T'T"),
  point_location: {
    latitude: 0,
    longitude: 0,
    address: '',
    country: '',
    province: '',
    city: '',
    postal: '',
    created_at: '',
    place_id: '',
  },
},
{
  stop_type: 'DESTINATION' as RoutePointStopType,
  stop_operation: 'DROPOFF' as const,
  point_number: 2,
  start_time: DateTime.now().toFormat("yyyy-MM-dd'T'T"),
  end_time: DateTime.now().toFormat("yyyy-MM-dd'T'T"),
  point_location: {
    latitude: 0,
    longitude: 0,
    address: '',
    country: '',
    province: '',
    city: '',
    postal: '',
    created_at: '',
    place_id: '',
  },
}];

function createDisplayableRoutePoint(
  routePoint: ShipmentTemplateRoutePoint,
): DisplayableRoutePoint {
  const readableAddress = `${routePoint.point_location?.address ? routePoint.point_location?.address : ''}, ${routePoint.point_location?.city ? routePoint.point_location?.city : ''}, ${routePoint.point_location?.province ?? ''} ${routePoint.point_location?.postal ?? ''}, ${routePoint.point_location?.country}`;
  const displayablePoint: DisplayableRoutePoint = {
    stop_type: routePoint.point.stop_type,
    stop_operation: routePoint.point.stop_operation,
    point_number: routePoint.point.point_number,
    point_location: routePoint.point_location,
    addressId: routePoint.point_location.place_id,
    addressReadable: readableAddress,
  };
  return displayablePoint;
}

function addMissingDefaultRoutePoint(
  routePoints: DisplayableRoutePoint[],
): DisplayableRoutePoint[] {
  const routePointsCopy = routePoints.slice();
  if (routePointsCopy.length !== 1) {
    throw new Error('Expected route points to have length 1');
  }

  if (routePointsCopy[0].stop_type === 'ORIGIN') {
    routePointsCopy.push(DEFAULT_ROUTE_POINTS[1]);
  } else {
    routePointsCopy.unshift(DEFAULT_ROUTE_POINTS[0]);
  }
  return routePointsCopy;
}

function tempateIsBookNow(tempate: ShipmentTemplate): boolean {
  return (tempate.content.shipment?.book_now_currency !== null
  && tempate.content.shipment?.book_now_currency !== undefined
  && tempate.content.shipment?.book_now_price !== null
  && tempate.content.shipment?.book_now_price !== undefined
  && tempate.content.shipment.type === 'SINGLE');
}

export function getCreateShipmentFormStateFromTemplateContent(template:
ShipmentTemplate):CreateShipmentFormState {
  let routePointsToDisplay: DisplayableRoutePoint[] = [];
  const routePointsEligibleForDisplay = template.content.route.filter(
    (point) => point.point_location !== undefined,
  );

  routePointsEligibleForDisplay.forEach((point) => {
    routePointsToDisplay.push(createDisplayableRoutePoint(
      point,
    ));
  });

  if (routePointsToDisplay.length === 1) {
    routePointsToDisplay = addMissingDefaultRoutePoint(routePointsToDisplay);
  }

  return {
    shipmentType: template.content.shipment?.type === 'SINGLE' || template.content.shipment?.type === 'MULTI_SHIPMENT_PROJECT' ? template.content.shipment.type : 'SINGLE',
    availableLoads: template.content?.project?.loads_available ? template.content?.project?.loads_available?.toString() : '',
    equipmentTypes: template.content.equipment_types ?? [],
    service: template.content.shipment.service ?? '',
    commodity: template.content.shipment.commodity ?? '',
    weightLb: template.content.shipment?.weight_lb ? template.content.shipment?.weight_lb.toString() : '',
    footage: template.content.shipment.footage ? template.content.shipment.footage?.toString() : '',
    specialRequirements: template.content.shipment?.special_requirements ?? '',
    additionalDetails: template.content.shipment?.additional_details ?? '',
    preferredPrice: template.content.shipment?.preferred_price ? template.content.shipment?.preferred_price?.toString() : '',
    currency: template.content.shipment.currency === null
    || template.content.shipment.currency === '' as string ? null : template.content.shipment.currency,

    routePoints: template.content.route == null
    || template.content.route.length === 0
      ? DEFAULT_ROUTE_POINTS
      : routePointsToDisplay,

    notificationSettingsType: template.content.notification_settings_type,
    notificationSetingsEmails: template.content.notification_settings_emails ?? [],
    commodityFreetext: template.content.shipment?.commodity_freetext ?? '',
    isBookNow: tempateIsBookNow(template),
    bookNowCurrency: template.content.shipment?.book_now_currency ?? 'CAD',
    bookNowPrice: template.content.shipment?.book_now_price ? template.content.shipment?.book_now_price.toString(10) : '0',
    customName: template.content.shipment?.custom_name ? template.content.shipment?.custom_name : '',
    emailNotificationType: 'ALL',
    shareeRoleIds: template.content?.sharee_role_ids ? template.content?.sharee_role_ids
      : [],
    shareShipment: (!!(template.content?.sharee_role_ids
    && template.content?.sharee_role_ids.length > 0)),
    partnerGroups: template.content?.notification_settings_partner_groups
      ? template.content?.notification_settings_partner_groups.map((id) => id.toString()) : [],
  };
}
