import InsightsIcon from '@mui/icons-material/Insights';
import { IconButton, Tooltip, Zoom } from '@mui/material';
import { useRoleContext } from 'hooks';
import { useNavigate } from 'react-router-dom';

export function DataPageNavBarButton() {
  const { role } = useRoleContext();
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/shippers/data');
  };

  if (role?.type === 'SHIPPER') {
    return (
      <Tooltip title="Data" placement="right" TransitionComponent={Zoom}>
        <IconButton color="inherit" onClick={onClick}>
          <InsightsIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <div />
  );
}
