import { Stack, useMediaQuery } from '@mui/material';
import { useRef } from 'react';
import { DashboardItemLayout, PageLayout } from '../../../../layouts';
import { CarrierQuoteList } from '../CarrierQuoteList';
import { ShipmentFilterSavedSearch } from '../ShipmentFilterSavedSearch';
import { HorizontalDashboard } from './HorizontalDashboard';

export function Dashboard() {
  const ref = useRef<HTMLDivElement | null>(null);
  const reduceDashboardItemSpacing = useMediaQuery('(max-width:1300px)');
  const useColumnLayout = useMediaQuery('(max-width:1350px)');

  const scrollToTop = () => {
    ref?.current?.scrollIntoView();
  };

  if (!useColumnLayout) {
    return (
      <HorizontalDashboard
        leftNode={(
          <DashboardItemLayout>
            <ShipmentFilterSavedSearch scrollToTop={scrollToTop} />
          </DashboardItemLayout>
        )}
        rightNode={(
          <CarrierQuoteList />
        )}
      />
    );
  }

  return (
    <PageLayout>
      <div style={{ display: 'hidden' }} ref={ref} />
      <Stack
        direction="column"
        spacing={reduceDashboardItemSpacing ? 4 : 8}
      >
        <DashboardItemLayout>
          <ShipmentFilterSavedSearch scrollToTop={scrollToTop} />
        </DashboardItemLayout>
        <CarrierQuoteList />
      </Stack>
    </PageLayout>
  );
}
