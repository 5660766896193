import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box, IconButton, Stack, Typography,
} from '@mui/material';
import { ISOStringToLocalReadableTimestamp, getEquipmentTypesDisplay } from 'utils';
import { getShipmentTemplateVisibiliyLabel } from './utils';

interface Props {
  template: ShipmentTemplate;
  onTemplateClicked: (template: ShipmentTemplate) => void;
  selectedTemplateId: number | null;
  onDeleteTemplate: (template: ShipmentTemplate) => void;
}

export function ShipmentTemplateListElement(props: Props) {
  const {
    template, onTemplateClicked, selectedTemplateId, onDeleteTemplate,
  } = props;
  return (
    <Box
      display="flex"
      px="1rem"
      py="1rem"
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
        },
        bgcolor: selectedTemplateId === template.id ? '#f8f8f8' : null,
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <Box flex={1} onClick={() => onTemplateClicked(template)}>
        <Stack direction="row">
          <Box>
            <Stack
              my="0.3rem"
              direction="row"
              alignItems="center"
              spacing={4}
              flexWrap="wrap"
            >
              <Typography>
                <b>Name:</b>
                {' '}
                {template.name}
              </Typography>
              {
                (template.content?.route?.length > 0
                && template.content?.route[0]?.point_location?.city
                ) && (
                  <Typography>
                    <b>Origin:</b>
                    {' '}
                    {template.content?.route[0]?.point_location?.city}
                  </Typography>
                )
              }
              {
                (template.content?.route?.length > 1
                && template.content?.route[1]?.point_location?.city
                ) && (
                  <Typography>
                    <b>Destination:</b>
                    {' '}
                    {template.content?.route[1]?.point_location?.city}
                  </Typography>
                )
              }
              {
                (
                  template?.content?.shipment?.service
                   && template?.content?.shipment?.service !== ''
                ) && (
                <Typography>
                  <b>Service:</b>
                  {' '}
                  {template?.content?.shipment?.service}
                </Typography>
                )
              }
              {
                (template?.content?.equipment_types
                  && template?.content?.equipment_types.length > 0)
                  && (
                    <Typography>
                      <b>Equipments:</b>
                      {' '}
                      {getEquipmentTypesDisplay(template.content.equipment_types)}
                    </Typography>
                  )
              }
              {
                (
                  getShipmentTemplateVisibiliyLabel(template) != null
                ) && (
                  <Typography>
                    <b>Visibility:</b>
                    {' '}
                    {getShipmentTemplateVisibiliyLabel(template)}
                  </Typography>
                )
              }
              {
                (template?.content?.shipment?.book_now_currency
                  && template?.content?.shipment?.book_now_price && (
                    <Typography>
                      <b>Book Now price:</b>
                      {' '}
                      {`$${template?.content.shipment.book_now_price} ${template?.content.shipment.book_now_currency}`}
                    </Typography>
                ))
              }
              <Typography>
                <b>Type:</b>
                {' '}
                {template.content.shipment?.type === undefined || template.content.shipment?.type === 'SINGLE' ? ' Single' : ''}
                {template.content.shipment?.type === 'MULTI_SHIPMENT_PROJECT' ? ' Project' : ''}
              </Typography>
            </Stack>
            <Typography variant="body2" color="text.secondary">
              Created:
              {' '}
              {ISOStringToLocalReadableTimestamp(template.created_at)}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box alignItems="center" justifyContent="center" display="flex">
        <IconButton onClick={() => { onDeleteTemplate(template); }}>
          <DeleteIcon fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
}
