import React, { useEffect, useRef, useState } from 'react';
import { PageLayout } from './PageLayout';

interface Props {
  minLeftWidth: number;
  maxLeftWidth: number;
  minRightWidth: number;
  maxRightWidth: number;
  defaultLeftWidth: number;
  leftChildren: React.ReactNode;
  rightChildren: React.ReactNode;
}

export function TwoElementResizablePage(props: Props) {
  const {
    minLeftWidth,
    maxLeftWidth,
    minRightWidth,
    maxRightWidth,
    defaultLeftWidth,
    leftChildren,
    rightChildren,
  } = props;

  const [isHovered, setIsHovered] = useState(false);
  const resizerRef = useRef<HTMLDivElement | null>(null);
  const leftSideRef = useRef<HTMLDivElement | null>(null);
  const rightSideRef = useRef<HTMLDivElement | null>(null);

  // const setLeftWidth = (width: number) => {
  //   if (leftSideRef.current) {
  //     leftSideRef.current.style.width = `${width}%`;
  //   }
  // };

  useEffect(() => {
    const resizer = resizerRef.current;
    const leftSide = leftSideRef.current;
    const rightSide = rightSideRef.current;

    if (!resizer || !leftSide || !rightSide) return;

    let x = 0;
    let leftWidth = 0;

    const mouseMoveHandler = (e: MouseEvent) => {
      const dx = e.clientX - x;
      // @ts-ignore
      const newLeftWidth = ((leftWidth + dx) * 100)
      // @ts-ignore
        / resizer.parentNode!.getBoundingClientRect().width;

      leftSide.style.width = `${newLeftWidth}%`;

      resizer.style.cursor = 'col-resize';
      document.body.style.cursor = 'col-resize';

      leftSide.style.userSelect = 'none';
      leftSide.style.pointerEvents = 'none';

      rightSide.style.userSelect = 'none';
      rightSide.style.pointerEvents = 'none';
    };

    const mouseUpHandler = () => {
      resizer.style.removeProperty('cursor');
      document.body.style.removeProperty('cursor');

      leftSide.style.removeProperty('user-select');
      leftSide.style.removeProperty('pointer-events');

      rightSide.style.removeProperty('user-select');
      rightSide.style.removeProperty('pointer-events');

      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

      if (isHovered) {
        setIsHovered(false);
      }
    };

    const mouseDownHandler = (e: MouseEvent) => {
      x = e.clientX;
      leftWidth = leftSide.getBoundingClientRect().width;

      document.addEventListener('mousemove', mouseMoveHandler);

      document.addEventListener('mouseup', mouseUpHandler);
    };

    resizer.addEventListener('mousedown', mouseDownHandler);
  }, [isHovered]);

  return (
    <PageLayout>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          ref={leftSideRef}
          style={{
            width: `${defaultLeftWidth}%`,
            display: 'flex',
            minWidth: `${minLeftWidth}%`,
            maxWidth: `${maxLeftWidth}%`,
            marginRight: '.1rem',
          }}
        >
          {leftChildren}
        </div>
        <div
          ref={resizerRef}
          style={{
            cursor: isHovered ? 'col-resize' : 'default',
            width: '1rem',
          }}
          onMouseEnter={() => setIsHovered(true)}
          id="dragMe"
        />
        <div
          ref={rightSideRef}
          style={{
            flex: 1,
            display: 'flex',
            minWidth: `${minRightWidth}%`,
            maxWidth: `${maxRightWidth}%`,
          }}
          id="rightSide"
        >
          {rightChildren}
        </div>
      </div>
    </PageLayout>
  );
}
